@font-face {
  font-family: 'Wigrum-Black';
  src: url("./assets/fonts/Wigrum-Black.otf") format("opentype");
}

@font-face {
  font-family: 'Wigrum-BlackItalic';
  src: url("./assets/fonts/Wigrum-BlackItalic.otf") format("opentype");
}

@font-face {
  font-family: 'Wigrum-Bold';
  src: url("./assets/fonts/Wigrum-Bold.otf") format("opentype");
}

@font-face {
  font-family: 'Wigrum-BoldItalic';
  src: url("./assets/fonts/Wigrum-BoldItalic.otf") format("opentype");
}

@font-face {
  font-family: 'Wigrum-Italic';
  src: url("./assets/fonts/Wigrum-Italic.otf") format("opentype");
}

@font-face {
  font-family: 'Wigrum-Light';
  src: url("./assets/fonts/Wigrum-Light.otf") format("opentype");
}

@font-face {
  font-family: 'Wigrum-LightItalic';
  src: url("./assets/fonts/Wigrum-LightItalic.otf") format("opentype");
}

@font-face {
  font-family: 'Wigrum-Medium';
  src: url("./assets/fonts/Wigrum-Medium.otf") format("opentype");
}

@font-face {
  font-family: 'Wigrum-MediumItalic';
  src: url("./assets/fonts/Wigrum-MediumItalic.otf") format("opentype");
}
@font-face {
  font-family: 'Wigrum-Regular';
  src: url("./assets/fonts/Wigrum-MediumItalic.otf") format("opentype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F2F6!important;
  line-height: 1!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.div-logo{
  height: 50px;
  width:100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:10px;
}


.home-container{
  height: 100vh;
  width: 100vw;
  background: url("../src/assets/img/bg/bg-animation-1440.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.txt-upload{
  text-align: center;
  font-family: 'Wigrum-Regular';
  font-size: 14px;
  color: #A4A4A4;
  margin: 0;
  padding-top: 20px;
}
.rec-upload{
  display: block;
  margin:25px auto 25px auto;
}
.div-fileSize{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 40px;
}
.txt-fileSize{
  color: #444444;
  font-family: 'Wigrum-Regular';
  font-size: 14px;
  margin: 0;
  word-break: break-word;
  max-width: 300px;
}
.txt-size{
  color: #B1B1B1;
  font-family: 'Wigrum-Regular';
  font-size: 14px;
  margin: 0;
}
.div-fileList{
  padding: 0 40px;
  overflow-y: scroll;
  height: 208px;
}
.div-file{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
}
.div-fileName{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.div-fileImage{
  margin-left: 10px;
}

.divUpload{
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 15px 0 25px 0;
}

.filelabel {
  width: 370px;
  border: 2px dashed grey;
  border-radius: 5px;
  display: block;
  padding: 5px;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
  margin: 0;
  padding: 20px 0;
}
.filelabel i {
  display: block;
  font-size: 30px;
  padding-bottom: 5px;
}
.filelabel i,
.filelabel .title {
  color: #A4A4A4;
  transition: 200ms color;

}
.filelabel:hover {
  border: 2px solid #356AFB;
}
.filelabel:hover i,
.filelabel:hover .title {
  color: #356AFB;
}
#FileInput{
  display:none;
}
.uploadBtn{
  margin: auto;
  display: block;
  height: 40px;
  width: 160px;
  background-color: #356AFB;
  border-color: #356AFB;
  color: #ffffff;
  font-family: 'Wigrum-Regular';
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
}

.input-code {
  display: block;
  margin:  auto;
  border: none;
  padding: 0;
  width: 7.5ch;
  background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1ch, transparent 0, transparent 1.5ch) 0 100%/ 7ch 2px no-repeat;
  font: 5ch droid sans mono, consolas, monospace;
  letter-spacing: 0.5ch;
}
.input-code:focus {
  outline: none;
  color: black;
}

.divCode{
  height: 57px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 15px 0 25px 0;
}

.fp-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f8f8f8ad;
  z-index: 9999999;
}
.fp-container .fp-loader {
  top: 50%;
  left: calc(50% - 60px);;
  position: absolute;
  width: 120px;
}

.closeModal{
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
}

.codeTxt{
  text-align: center;
  font-size: 35px;
  font-family: 'Wigrum-BoldItalic';
  margin-top: 25px;
}
.popup-txt{
  font-family: 'Wigrum-Regular';
  margin: 0;
  font-size: 17px;
}
.errorModal{
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border-radius: .25rem;
}

.bluredDownload{
  filter: blur(2px);
  -webkit-filter: blur(2px);
  cursor: pointer;
  height:35px;
}
.sizeError{
  color: red;
}
button:disabled{
  cursor: not-allowed!important;
  opacity: 0.65!important;
}

.coolTabs-div{
  width:460px;
  height: 450px;
}

@media only screen and (max-width: 500px) {
  .coolTabs-div{
    width:330px;
  }
  .row{
    margin-right: 0!important;
    margin-left: 0!important;
  }
  .home-container {
    height: 100vh;
    width: 100vw;
    background: url("../src/assets/img/bg/bg-mobile.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .txt-upload{
    font-size: 13px;
  }
  .filelabel {
    width: 300px;
  }
  .div-fileSize {
    margin: 0 15px;
  }
  .div-fileList {
    padding: 0px 15px;
  }
}
